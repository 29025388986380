import moment from "moment";

export const validatorPositive = (value) => {
  if (value >= 0) {
    return true;
  }
  return false;
};

export const validatorPassword = (password) => {
  /* eslint-disable no-useless-escape */
  // const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*();:]).{6,}/
  const regExp = /(?=.*\d)(?=.*[a-zA-Z]).{6,}/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);
  return validPassword;
};

export const validatorConfirmPassword = (value, [password]) =>
  value === password;

export const validatorCreditCard = (creditnum) => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/;
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum);
  return validCreditCard;
};

export const validatorUrlValidator = (val) => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorCheckinTime = (time) => {
  if (time === null) return null;
  const timepickerFormat = "HH:mm:ss";
  const minTime = moment("16:00:00", timepickerFormat);
  const maxTime = moment("02:00:00", timepickerFormat);
  return !moment(time, timepickerFormat).isBetween(
    maxTime,
    minTime,
    null,
    "()"
  );
};

export const validatorCheckoutTime = (time) => {
  if (time == null) return null;
  const timepickerFormat = "HH:mm:ss";
  const minTime = moment("00:00:00", timepickerFormat);
  const maxTime = moment("10:00:00", timepickerFormat);
  return moment(time, timepickerFormat).isBetween(minTime, maxTime, null, "[]");
};

// The valid parameter is coming from the vue-phone-number-input, where
// the phone number has already been validated
export const validatorPhone = (value, [valid]) =>
  valid === "true" || valid === true;

export const validatorPolicy = (accepted) => !!accepted;

export const validatorPastDate = (value) => {
  if (value == null) return null;
  const dateFormat = "YYYY-MM-DD";
  const formattedDate = moment(value, dateFormat);
  const today = moment();
  const isBefore = formattedDate.isBefore(today, "day");
  return isBefore;
};
